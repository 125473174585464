import { Moment } from "moment/moment";

export interface SpecialItemPriceDTO {
    id?: number;

    itemType: CartItemType;
    validFrom: Moment | string;

    priceNet: number;
    vat: number;
    priceGross: number;
    productId?: number;
    ean?: string;
    description?: string;
    label?: string;
    imageLink?: string;
}

export interface EditSpecialItemPriceDTO extends SpecialItemPriceDTO{
    validFromNow: boolean;
}

export enum CartItemType {
    ARTICLE = "ARTICLE",
    MOUNTINGKIT = "MOUNTINGKIT",
    RIM = "RIM",
    TPMS = "TPMS",
    TYRE = "TYRE",
    VOUCHER = "VOUCHER",
    WHEELASSEMBLY = "WHEELASSEMBLY",
    HEAVY_WHEELASSEMBLY = "HEAVY_WHEELASSEMBLY",
    DEPOSIT = "DEPOSIT"
}
