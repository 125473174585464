import { Formik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import axiosInstance from "../../api";
import { CartItemType, EditSpecialItemPriceDTO, SpecialItemPriceDTO } from "./models/SpecialItemPriceDTO";

const cartItemTypes = Object.values(CartItemType);

export function SpecialItemPriceDetails () {
    const [t] = useTranslation();
    const navigate = useNavigate();
    let { id } = useParams<{ id: string }>();
    const create = id === "create";
    const initialFormValues: EditSpecialItemPriceDTO = useMemo<EditSpecialItemPriceDTO>(() => {
        return {
            itemType: undefined,
            validFrom: moment(),
            validFromNow: true,
            priceNet: null,
            vat: null,
            priceGross: null,
            productId: null,
            ean: "",
            description: "",
            label: "",
            imageLink: ""
        };
    }, []);

    const validationSchema = yup.object().shape({
        itemType: yup.mixed<CartItemType>().nullable().oneOf(cartItemTypes),
        validFrom: yup.date().when('validFromNow', {
            is: (validFromNow: boolean) => validFromNow,
            then: (schema) => schema.optional(),
            otherwise: (schema) => schema.min(moment().format('YYYY-MM-DD HH:mm'), t("VALIDATION.START_DATE_MAX_ERROR"))
                .required(t("VALIDATION.REQUIRED"))
        }),
        validFromNow: yup.bool(),
        priceNet: yup.number().required(t("VALIDATION.REQUIRED"))
            .positive(t("VALIDATION.VALUE_MUST_BE_POSITIVE")),
        vat: yup.number().required(t("VALIDATION.REQUIRED"))
            .positive(t("VALIDATION.VALUE_MUST_BE_POSITIVE")),
        priceGross: yup.number().required(t("VALIDATION.REQUIRED"))
            .positive(t("VALIDATION.VALUE_MUST_BE_POSITIVE")),
    });

    const [loadedFormValues, setLoadedFormValues] = useState<EditSpecialItemPriceDTO | null>(null);

    useEffect(() => {
        if (!create) {
            axiosInstance.get<EditSpecialItemPriceDTO[]>("/specialitemprice").then(response => {
                setLoadedFormValues({
                    ...response.data.find(e => e.id === +id),
                    validFrom: moment(),
                    validFromNow: true
                });
            });
        }
    }, [create, t, id, initialFormValues]);

    function submitForm(data: EditSpecialItemPriceDTO) {
        let specialItemPrice: EditSpecialItemPriceDTO = { ...data };
        if(data.validFromNow){
            data.validFrom = moment();
        }
        createSpecialItemPrice(specialItemPrice);
    }

    function createSpecialItemPrice(specialItemPrice: EditSpecialItemPriceDTO) {
        axiosInstance.post("/specialitemprice", specialItemPrice).then(() => {
            navigate("/special-item-prices");
        });
    }

    return (
        <div>
            <Formik
                validationSchema={validationSchema}
                onSubmit={(data) => submitForm(data)}
                initialValues={loadedFormValues || initialFormValues}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                      setFieldTouched,
                      errors
                  }) => {
                    return (
                        <div>
                            <div className="d-flex justify-content-between align-items-center pb-3">
                                <h3>{t("SPECIAL_ITEM_PRICE." + (create ? "CREATE" : "EDIT"))}</h3>
                                <Button variant="primary" href="/special-item-prices">{t("SPECIAL_ITEM_PRICE.BACK")}</Button>
                            </div>
                            <Form onSubmit={handleSubmit} className={"form"} noValidate>
                                <Form.Group className="pb-3" controlId="itemTypeControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.ITEM_TYPE")}</Form.Label>
                                    <Form.Select name="type"
                                                 value={values.itemType}
                                                 onChange={handleChange}
                                                 onBlur={handleBlur}
                                                 isInvalid={!!(touched.itemType && errors.itemType)}
                                                 required>
                                        {cartItemTypes.map((type) =>
                                            <option key={type} value={type}>
                                                {t("ENUMS.CART_ITEM_TYPE." + type)}
                                            </option>
                                        )}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.itemType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="validFromNowControl">
                                    <Form.Check type="checkbox"
                                                label={t('SPECIAL_ITEM_PRICE.VALID_FROM_NOW')}
                                                name="validFromNow"
                                                checked={values.validFromNow}
                                                onChange={handleChange}
                                                onBlur={handleBlur}/>
                                </Form.Group>
                                <Form.Group className={"pb-3" + (values.validFromNow ? ' d-none' : '')} controlId="validFromControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.VALID_FROM")}</Form.Label>
                                    <Datetime value={moment(values.validFrom)}
                                              onChange={e => {
                                                  setFieldValue("validFrom", e);
                                                  setFieldTouched("validFrom");
                                              }}
                                              inputProps={(touched.validFrom && errors.validFrom) ?
                                                  {
                                                      required: true,
                                                      name: "validFrom",
                                                      className: "form-control is-invalid",
                                                      disabled: values.validFromNow
                                                  } : {
                                                      required: true,
                                                      name: "validFrom",
                                                      className: "form-control",
                                                      disabled: values.validFromNow
                                                  }
                                              }
                                              timeFormat={true}
                                              closeOnSelect={true}
                                              renderInput={(props) => {
                                                  return <input {...props} value={props.value} />;
                                              }}
                                    />
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="priceNetControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.PRICE_NET")}</Form.Label>
                                    <Form.Control name="priceNet"
                                                  type="number"
                                                  value={values.priceNet}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.priceNet && errors.priceNet)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.priceNet}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="vatControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.VAT")}</Form.Label>
                                    <Form.Control name="vat"
                                                  type="number"
                                                  value={values.vat}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.vat && errors.vat)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vat}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="priceGrossControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.PRICE_GROSS")}</Form.Label>
                                    <Form.Control name="priceGross"
                                                  type="number"
                                                  value={values.priceGross}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.priceGross && errors.priceGross)}
                                                  required>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.priceGross}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="productIdControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.PRODUCT_ID")}</Form.Label>
                                    <Form.Control name="productId"
                                                  type="number"
                                                  value={values.productId}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.productId && errors.productId)}>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.productId}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="eanControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.EAN")}</Form.Label>
                                    <Form.Control name="ean"
                                                  type="text"
                                                  value={values.ean}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.ean && errors.ean)}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="descriptionControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.DESCRIPTION")}</Form.Label>
                                    <Form.Control name="description"
                                                  type="text"
                                                  value={values.description}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.description && errors.description)}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="labelControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.LABEL")}</Form.Label>
                                    <Form.Control name="label"
                                                  type="text"
                                                  value={values.label}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.label && errors.label)}>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="pb-3" controlId="imageLinkControl">
                                    <Form.Label>{t("SPECIAL_ITEM_PRICE.IMAGE_LINK")}</Form.Label>
                                    <Form.Control name="imageLink"
                                                  type="text"
                                                  value={values.imageLink}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={!!(touched.imageLink && errors.imageLink)}>
                                    </Form.Control>
                                </Form.Group>
                                <Button variant="primary" type="submit" disabled={!(dirty && isValid)}>
                                    {t("SPECIAL_ITEM_PRICE.SAVE")}
                                </Button>
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );

}
