import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api";
import { SpecialItemPriceDTO } from "./models/SpecialItemPriceDTO";

export function SpecialItemPrices(){
    const [specialItemPrices, setSpecialItemPrices] = useState<SpecialItemPriceDTO[]>([]);
    const [t] = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        getSpecialItemPrices();
    }, []);

    const goToDetailPage = (id: number | undefined) => {
        navigate('/special-item-prices/' + id);
    }

    const handleDelete = (id: number | undefined, event: any) => {
        event.stopPropagation();
        axiosInstance.delete('/specialitemprice/' + id).then(response => {
            getSpecialItemPrices();
        });
    }

    const getSpecialItemPrices = () => {
        axiosInstance.get<SpecialItemPriceDTO[]>('/specialitemprice').then(response => {
            setSpecialItemPrices(response.data);
        });
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h3>{t('NAVIGATION.SPECIAL_ITEM_PRICES')}</h3>
                <Button variant="primary" href="/special-item-prices/create">{t('SPECIAL_ITEM_PRICE.NEW')}</Button>
            </div>
            <Table striped hover>
                <thead>
                <tr>
                    <th>{t('SPECIAL_ITEM_PRICE.ITEM_TYPE')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.VALID_FROM')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.PRICE_NET')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.VAT')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.PRICE_GROSS')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.PRODUCT_ID')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.EAN')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.DESCRIPTION')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.LABEL')}</th>
                    <th>{t('SPECIAL_ITEM_PRICE.IMAGE_LINK')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {specialItemPrices.map((item) =>
                    <tr className="click" key={item.id} onClick={() => goToDetailPage(item.id)}>
                        <td>{item.itemType ? t('ENUMS.CART_ITEM_TYPE.' + item.itemType) : ''}</td>
                        <td>{moment(item.validFrom).format('DD.MM.YYYY')}</td>
                        <td>{<NumericFormat value={item.priceNet} displayType={'text'} decimalSeparator={','}
                                     decimalScale={2} fixedDecimalScale={true} suffix={'€'}/>}</td>
                        <td>{<NumericFormat value={item.vat} displayType={'text'} decimalSeparator={','}
                                     decimalScale={2} fixedDecimalScale={true} suffix={'€'}/>}</td>
                        <td>{<NumericFormat value={item.priceGross} displayType={'text'} decimalSeparator={','}
                                     decimalScale={2} fixedDecimalScale={true} suffix={'€'}/>}</td>
                        <td>{item.productId}</td>
                        <td>{item.ean}</td>
                        <td>{item.description}</td>
                        <td>{item.label}</td>
                        <td>{item.imageLink}</td>
                        <td className="text-end">
                            <Button variant="primary" onClick={(e) => handleDelete(item.id, e)}>
                                {t('SPECIAL_ITEM_PRICE.DELETE')}
                            </Button></td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}
